
import { mapGetters, mapState } from "vuex";
import { getLocalStoragePopups, setLocalStoragePopups } from "~/utils";
import contentLinkMixin from "~/mixins/contentLink.js";
import SubscriptionFormSuccessInPopUp from "~/components/shared/forms/SubscriptionFormSuccessInPopUp.vue";

export default {
  name: "MarketingPopup",
  components: { SubscriptionFormSuccessInPopUp },
  mixins: [contentLinkMixin],
  data() {
    return {
      inDivElement: null,
      showSuccessSubscription: false,
    };
  },
  computed: {
    ...mapGetters({
      appBreakpoints: "general/getAppBreakpoints",
    }),
    ...mapState({
      sitePopup: (state) => state.general.sitePopup,
      subscriptionFormData: (state) => state.general.subscriptionFormData,
    }),
    showPopup: {
      get() {
        return Boolean(this.sitePopup);
      },
      set(val) {
        this.$store.commit("general/SET_SITE_POPUP", val);
      },
    },
    subscriptionInPopupData() {
      return {
        buttonText:
          this.subscriptionFormData.mixedTranslation.content.buttonText,
        errorText: this.subscriptionFormData.mixedTranslation.content.errorText,
        inputPlaceholder:
          this.subscriptionFormData.mixedTranslation.content.inputPlaceholder,
        note: this.subscriptionFormData.mixedTranslation.content.note,
        successMessage:
          this.subscriptionFormData.mixedTranslation.content.successMessage,
      };
    },
  },
  watch: {
    showPopup: {
      handler(value) {
        if (value) {
          document.addEventListener("keyup", this.onKeyUp);
        } else {
          document.removeEventListener("keyup", this.onKeyUp);
        }
      },
    },
    sitePopup: {
      handler(value) {
        if (!value) return;
        const htmlContent = value.mixedTranslation.content;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");
        setTimeout(() => {
          this.inDivElement = doc.querySelector(
            "div[data-insert='subscription-form']"
          );
        }, 0);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    closeModal() {
      const storePopups = getLocalStoragePopups();

      setLocalStoragePopups(
        storePopups.map((popup) => {
          return popup.id === this.sitePopup.id
            ? {
                ...popup,
                last_opened_at: new Date(),
              }
            : popup;
        })
      );

      this.showPopup = null;
    },
    contentClick(e) {
      const link = e.target.closest("a")?.href;
      if (link) {
        const url = new URL(link);
        if (window.location.host === url.host && !url.hash) {
          e.preventDefault();
          this.$router.push(this.localePath(url.pathname + url.search));
          this.closeModal();
        }
      }
    },
    onKeyUp(evt) {
      if (evt.keyCode === 27) {
        this.closeModal();
      }
    },
  },
};
